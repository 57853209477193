import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from 'dpl/common/components/Icon';

export default function OverlayNavigationArrow({ direction, onClick }) {
  return (
    <button
      className={classnames(
        `OverlayNavigationArrow transform-center ${direction}-0 z-max`,
        {
          OverlayNavigationArrow__nextArrow: direction === 'right',
          OverlayNavigationArrow__previousArrow: direction === 'left'
        }
      )}
      onClick={onClick}
      type="button"
    >
      <Icon
        className="silver text-shadow"
        direction={direction}
        height="48px"
        name={`arrow-${direction}`}
        width="48px"
      />
    </button>
  );
}

OverlayNavigationArrow.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func.isRequired
};
