import React from 'react';
import PropTypes from 'prop-types';

import SmartImage from 'dpl/components/SmartImage';
import TooltipWrapper from 'dpl/common/tooltip/components/TooltipWrapper';
import { isLessThanBreakpoint } from 'dpl/util/grid';

import preferredBreederBadgeSvg from 'dpl/assets/preferred_breeder_badge.svg';

const IS_LT_MD = isLessThanBreakpoint('md');

const defaultBadgeDimensions = IS_LT_MD
  ? { width: '28px', height: '32px' }
  : { width: '40px', height: '44px' };

function PreferredBreederBadgeWrapper({ children, hideTooltip }) {
  return (
    <>
      {hideTooltip ? (
        <>{children}</>
      ) : (
        <TooltipWrapper
          className="pointer-events-auto"
          title="Good Dog Preferred Breeders offer an exceptional owner experience and respond quickly"
          minWidth="300px"
        >
          {children}
        </TooltipWrapper>
      )}
    </>
  );
}

PreferredBreederBadgeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  hideTooltip: PropTypes.bool
};

PreferredBreederBadgeWrapper.defaultProps = {
  hideTooltip: false
};

export default function PreferredBreederBadge({
  className,
  height,
  width,
  hideTooltip
}) {
  return (
    <PreferredBreederBadgeWrapper hideTooltip={hideTooltip}>
      <SmartImage
        width={width}
        height={height}
        className="drop-shadow"
        wrapperOnlyClassName={className}
        src={preferredBreederBadgeSvg}
      />
    </PreferredBreederBadgeWrapper>
  );
}

PreferredBreederBadge.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  hideTooltip: PropTypes.bool
};

PreferredBreederBadge.defaultProps = {
  className: '',
  width: defaultBadgeDimensions.width,
  height: defaultBadgeDimensions.height,
  hideTooltip: false
};
