import { useDispatch, useSelector } from 'react-redux';

import { pushParams, resetParams } from 'dpl/actions/query_params';
import { ANALYTICS, DOG_ID } from 'dpl/constants/query_params';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { breederProfileDogsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { dogResourceDefinition } from 'dpl/dog_modals/utils/queryDefinitions';

export default function useParentModal(props = {}) {
  const queryParams = useSelector(state => state.queryParams);
  const dispatch = useDispatch();

  const currentParentId = props.dogId || Number(queryParams[DOG_ID]);

  const isOpen = Boolean(currentParentId);

  const dogData = useResourceQuery(dogResourceDefinition(currentParentId));

  const {
    value: { data: currentDog }
  } = dogData;

  const dogsData = useResourceQuery(
    breederProfileDogsResourceDefinition(currentDog.breeder_profile?.id)
  );

  const {
    value: {
      data: { dogs }
    }
  } = dogsData;

  const currentDogIdx = dogs.findIndex(({ id }) => id === currentParentId);

  const dogImages = [...(currentDog.photographs || [])];

  if (currentDog.profile_photo_url) {
    dogImages.unshift({
      id: currentDog.profile_photo_id,
      url: currentDog.profile_photo_url
    });
  }

  function resetParentModalParams() {
    if (typeof props.onClose === 'function') {
      props.onClose();
    } else {
      dispatch(resetParams(DOG_ID, ANALYTICS, { hash: true }));
    }
  }

  function handleDogClick(dogId) {
    if (typeof props.onDogClick === 'function') {
      props.onDogClick(dogId);
    } else {
      resetParentModalParams();
      dispatch(pushParams({ [DOG_ID]: dogId }, { hash: true }));
    }
  }

  return {
    isOpen,
    onClose: resetParentModalParams,
    onDogClick: handleDogClick,
    isLoading: dogData.isLoading,
    dog: currentDog,
    previousDog: dogs[currentDogIdx - 1],
    nextDog: dogs[currentDogIdx + 1],
    dogImages
  };
}
