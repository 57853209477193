export function userFollowsQueryDefinition(params) {
  return {
    url: `/api/user_follows.json`,
    params,
    defaultValue: {
      data: [],
      meta: {}
    }
  };
}

export function createUserFollowMutationDefinition() {
  return {
    url: '/api/user_follows.json',
    method: 'POST'
  };
}

export function removeUserFollowMutationDefinition(userFollowsId) {
  return {
    url: `/api/user_follows/${userFollowsId}.json`,
    method: 'DELETE'
  };
}

export function createBreederProfileFollowMutationDefinition(buyerProfileId) {
  return {
    url: `/api/buyer_profiles/${buyerProfileId}/followables/breeder_profiles.json`,
    method: 'POST'
  };
}

export function removeBreederProfileFollowMutationDefinition(
  breederProfileId,
  buyerProfileId
) {
  return {
    url: `/api/buyer_profiles/${buyerProfileId}/followables/breeder_profiles/${breederProfileId}.json`,
    method: 'DELETE'
  };
}

export function breederProfileFollowersResourceDefinition(breederProfileId) {
  return {
    url: `/api/breeder_profiles/${breederProfileId}/followers.json`
  };
}
