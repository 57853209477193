import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'redux';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { BREED_ID } from 'dpl/constants/query_params';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import { EVENT_CATEGORIES, EVENT_ACTION_IDS } from 'dpl/constants/analytics';
import { trackEvent, trackConversion } from 'dpl/util/analytics';
import { get } from 'dpl/shared/utils/object';
import Icon from 'dpl/common/components/Icon';
import { breederProfileAvailabilityResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';

export default function ModalApplyButton({
  breederProfileUrl,
  breederProfileId,
  analytics,
  className,
  withIcon,
  customCopy
}) {
  const currentUser = useCurrentUser();

  const handleApplyClick = compose(
    trackEvent(EVENT_CATEGORIES.APPLY_TO_BREEDER),
    trackConversion(EVENT_ACTION_IDS.APPLY_TO_BREEDER)
  )(() => {
    const breederProfileEndpoint = breederProfileUrl.split('#')[0];
    const view = analytics?.view ?? '';

    window.location.href = `${breederProfileEndpoint}/contacts/buyer_application?view=${view}`;
  });

  const breedId = useSelector(({ queryParams }) =>
    Number(queryParams[BREED_ID])
  );

  const breederAvailabilityData = useResourceQuery(
    breederProfileAvailabilityResourceDefinition(breederProfileId)
  );

  const {
    value: {
      data: { breeds }
    }
  } = breederAvailabilityData;

  const availableBreeds = breeds.filter(
    b => !b.snoozed && (breedId ? b.id === breedId : true)
  );

  const appliedTo = get(
    currentUser,
    'value.data.breeder_profiles_applied_to',
    []
  );

  const canApply =
    typeof currentUser?.isLoggedIn === 'boolean' &&
    !appliedTo.includes(breederProfileId);

  if (availableBreeds.length === 0 || !canApply) {
    return null;
  }

  const defaultCopy = (
    <>
      Apply&nbsp;
      <span className="d-none d-sm-inline">to Breeder</span>
    </>
  );

  return (
    <button
      className={classnames(
        'ModalApplyButton button button--primary fw-medium br3 bw0',
        className
      )}
      onClick={handleApplyClick}
      type="button"
    >
      {withIcon && <Icon className="mr3" name="application" />}
      {customCopy ? <span>{customCopy}</span> : defaultCopy}
    </button>
  );
}

ModalApplyButton.propTypes = {
  breederProfileUrl: PropTypes.string.isRequired,
  breederProfileId: PropTypes.number.isRequired,
  analytics: PropTypes.shape({
    breeder_preferred_status: PropTypes.string,
    page_number: PropTypes.number,
    position: PropTypes.number,
    view: PropTypes.string
  }),
  className: PropTypes.string,
  withIcon: PropTypes.bool,
  customCopy: PropTypes.string
};

ModalApplyButton.defaultProps = {
  analytics: {},
  className: 'pv3 w-100 flex justify-center mt3 mb6',
  withIcon: false,
  customCopy: null
};
