import { ANALYTICS_ORIGIN_KEY } from '../constants/localStorage';
import { getAnalyticsOrigin } from './getAnalyticsOrigin';
import { setLocalStorageValue } from './localStorage';

export function setAnalyticsOrigin({
  originView,
  originViewFeature,
  originViewFeatureLocation
}) {
  const {
    origin_view: previousOriginView,
    origin_view_feature: previousOriginViewFeature,
    origin_view_feature_location: previousOriginViewFeatureLocation
  } = getAnalyticsOrigin() || {};

  const isOriginViewChanged = originView && previousOriginView !== originView;

  if (isOriginViewChanged) {
    setLocalStorageValue(ANALYTICS_ORIGIN_KEY, {
      origin_view: originView,
      origin_view_feature: originViewFeature,
      origin_view_feature_location: originViewFeatureLocation
    });
    return;
  }

  const isOriginViewFeatureChanged =
    originViewFeature && previousOriginViewFeature !== originViewFeature;

  const isOriginViewFeatureLocationChanged =
    originViewFeatureLocation &&
    previousOriginViewFeatureLocation !== originViewFeatureLocation;

  if (isOriginViewFeatureChanged || isOriginViewFeatureLocationChanged) {
    setLocalStorageValue(ANALYTICS_ORIGIN_KEY, {
      origin_view: previousOriginView,
      origin_view_feature: originViewFeature,
      origin_view_feature_location: originViewFeatureLocation
    });
  }
}
