import { DESCRIPTIVE_BADGE_VARIANTS } from 'dpl/common/utils/constants';

export const APPLICATION_STATUSES = {
  INCOMPLETE: 'Incomplete',
  PENDING: 'Pending',
  RESPONDED: 'Responded',
  APPROVED: 'Approved',
  DECLINED: 'Reviewed',
  WITHDRAWN: 'Withdrawn'
};

export const APPLICATION_STATUS_TO_BADGE_VARIANT = {
  [APPLICATION_STATUSES.INCOMPLETE]: DESCRIPTIVE_BADGE_VARIANTS.ERROR,
  [APPLICATION_STATUSES.PENDING]: DESCRIPTIVE_BADGE_VARIANTS.WARNING,
  [APPLICATION_STATUSES.RESPONDED]: DESCRIPTIVE_BADGE_VARIANTS.SUCCESS,
  [APPLICATION_STATUSES.APPROVED]: DESCRIPTIVE_BADGE_VARIANTS.SUCCESS,
  [APPLICATION_STATUSES.DECLINED]: DESCRIPTIVE_BADGE_VARIANTS.ERROR,
  [APPLICATION_STATUSES.WITHDRAWN]: DESCRIPTIVE_BADGE_VARIANTS.ERROR
};

export const APPLICATION_STATUS_TO_COLOR = {
  [APPLICATION_STATUSES.INCOMPLETE]: 'red',
  [APPLICATION_STATUSES.PENDING]: 'gold',
  [APPLICATION_STATUSES.RESPONDED]: 'green',
  [APPLICATION_STATUSES.APPROVED]: 'green',
  [APPLICATION_STATUSES.DECLINED]: 'red',
  [APPLICATION_STATUSES.WITHDRAWN]: 'red'
};

export const AUTH_FORM_TYPES = {
  LOGIN: 'Log in',
  SIGNUP: 'Sign up'
};

export const CONFETTI_STORAGE_KEY = 'confettied_steps';

export const STRIPE_SETUP_INTENT = 'setup_intent';
export const STRIPE_PAYMENT_INTENT = 'payment_intent';
