import React from 'react';
import PropTypes from 'prop-types';

import { AUTH_FORM_TYPES } from '../utils/constants';

export default function BuyerAuthModalFooter({
  linkedFormType,
  onToggleFormTypeClick
}) {
  return (
    <div className="BuyerAuthModalFooter pv6 tc bt b--light-gray">
      <span>
        {linkedFormType === AUTH_FORM_TYPES.SIGNUP ? "Don't" : 'Already'} have
        an account?
      </span>
      &nbsp;
      <button
        data-test-id="auth-modal-toggle"
        className="BuyerAuthModalFooter__toggle primary link--primary"
        onClick={() =>
          linkedFormType === AUTH_FORM_TYPES.SIGNUP
            ? onToggleFormTypeClick(AUTH_FORM_TYPES.SIGNUP)
            : onToggleFormTypeClick(AUTH_FORM_TYPES.LOGIN)
        }
        type="button"
      >
        {linkedFormType}
      </button>
    </div>
  );
}

BuyerAuthModalFooter.propTypes = {
  linkedFormType: PropTypes.string.isRequired,
  onToggleFormTypeClick: PropTypes.func.isRequired
};
