import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ClickableDiv from 'dpl/common/components/ClickableDiv';
import SmartImage from 'dpl/components/SmartImage';
import DogPlaceholderSvg from 'dpl/common/components/DogPlaceholderSvg';

export default function BreederProfileFamilyOverlayPuppyItem({
  name,
  onClick,
  photographUrl,
  reserved,
  subtitle
}) {
  return (
    <div className="BreederProfileFamilyOverlayPuppyItem">
      <ClickableDiv onClick={onClick}>
        <div className="pl1 pr3 pv1 flex align-items-center justify-between hide-child hover-bg-lightest-purple">
          <div className="flex align-items-center overflow-hidden">
            <div className="relative f0">
              {photographUrl ? (
                <SmartImage
                  Tag="div"
                  className="bg-center cover br-100"
                  height="48px"
                  width="48px"
                  crop
                  src={photographUrl}
                />
              ) : (
                <DogPlaceholderSvg
                  className="w-100 h-100 bg-near-white br-100"
                  height="48px"
                  width="48px"
                  puppy
                />
              )}
              <span
                className={classnames('ph1 pv1 absolute top-0 right-0 br-100', {
                  'bg-red': reserved,
                  'bg-green': !reserved
                })}
              />
            </div>
            <div className="ml3 overflow-hidden">
              <p className="truncate">{name}</p>
              {subtitle && <p className="f2">{subtitle}</p>}
            </div>
          </div>
          <span className="child stone-700 fw-medium">view</span>
        </div>
      </ClickableDiv>
    </div>
  );
}

BreederProfileFamilyOverlayPuppyItem.propTypes = {
  name: PropTypes.string.isRequired,
  photographUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  reserved: PropTypes.bool.isRequired,
  subtitle: PropTypes.string
};

BreederProfileFamilyOverlayPuppyItem.defaultProps = {
  photographUrl: null,
  subtitle: null
};
