import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import LoginFormContainer from 'dpl/containers/LoginFormContainer';
import SignupFormContainer from 'dpl/containers/SignupFormContainer';
import { USER_TYPES } from 'dpl/shared/constants/shared';

import { AUTH_FORM_TYPES } from '../utils/constants';
import BuyerAuthModalFooter from './BuyerAuthModalFooter';

export default function BuyerAuthModal({ onCloseClick, onSuccessfulAuth }) {
  const [formType, setFormType] = useState(AUTH_FORM_TYPES.SIGNUP);

  const isSignupFormType = formType === AUTH_FORM_TYPES.SIGNUP;
  const FormComponent = isSignupFormType
    ? SignupFormContainer
    : LoginFormContainer;
  const headerText = isSignupFormType
    ? 'Create an account to continue'
    : 'Log in to continue';
  const linkedFormType = isSignupFormType
    ? AUTH_FORM_TYPES.LOGIN
    : AUTH_FORM_TYPES.SIGNUP;

  return (
    <MiniOverlayModal className="BuyerAuthModal" onCloseClick={onCloseClick}>
      <div className="ph20-md ph6 pt10">
        <h5 className="tc mb8">{headerText}</h5>
        <FormComponent
          hideForgotPassword
          onAuth={onSuccessfulAuth}
          userType={USER_TYPES.BUYER}
        />
        <BuyerAuthModalFooter
          linkedFormType={linkedFormType}
          onToggleFormTypeClick={setFormType}
        />
      </div>
    </MiniOverlayModal>
  );
}

BuyerAuthModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onSuccessfulAuth: PropTypes.func.isRequired
};
