import { USER_TYPES } from 'dpl/shared/constants/shared';
import {
  BREEDER_ONBOARDING_PATH,
  BUYER_MESSAGES_PATH
} from 'dpl/shared/constants/urls';
import { getSafeRedirectPath } from 'dpl/util/queryString';

export default function postSignupRedirect(userType) {
  const redirectTo = getSafeRedirectPath();

  if (redirectTo) {
    window.location = redirectTo;
  } else if (userType === USER_TYPES.BREEDER) {
    window.location = BREEDER_ONBOARDING_PATH;
  } else {
    window.location = BUYER_MESSAGES_PATH;
  }
}
