import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ErrorWrapper from 'dpl/form/components/ErrorWrapper';
import Icon from 'dpl/common/components/Icon';

export default function GenericSelect({
  name,
  value,
  onChange,
  placeholder,
  options,
  className,
  id,
  errors,
  displayErrors,
  arrowClassName,
  disablePlaceholder,
  arrowSize,
  disabled
}) {
  return (
    <div
      className={classnames(
        'GenericSelect relative overflow-hidden w-100 font-16',
        {
          'GenericSelect--disabled': disabled,
          'GenericSelect--enabled pointer': !disabled
        }
      )}
    >
      <div className="relative">
        <ErrorWrapper errors={errors} isShown={displayErrors}>
          <>
            <select
              id={id}
              name={name}
              value={value || ''}
              onChange={onChange}
              className={classnames(
                'pointer w-100 input-reset ba br3 b--light-gray pv3 ph4 bg-white',
                className,
                {
                  'mid-gray': !value
                }
              )}
              disabled={disabled}
              data-test-id={id}
            >
              <>
                <option value="" disabled={disablePlaceholder}>
                  {placeholder}
                </option>
                {options.map(option => {
                  if (typeof option === 'string') {
                    option = { value: option, title: option };
                  }

                  return (
                    <option
                      value={option.value || option.id}
                      key={option.value || option.id}
                    >
                      {option.title || option.name}
                    </option>
                  );
                })}
              </>
            </select>
            {!disabled && (
              <Icon
                className={classnames(
                  'mr2 z-3 pointer-events-none transform-center right-0',
                  arrowClassName
                )}
                name="fetch-chevron-down"
                width={arrowSize}
                height={arrowSize}
              />
            )}
          </>
        </ErrorWrapper>
      </div>
    </div>
  );
}

GenericSelect.defaultProps = {
  name: null,
  className: '',
  placeholder: 'Select',
  value: null,
  id: null,
  errors: [],
  displayErrors: false,
  arrowClassName: '',
  disablePlaceholder: false,
  arrowSize: '24px',
  disabled: false
};

GenericSelect.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.instanceOf(Date)
        ]),
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        title: PropTypes.string
      }),
      PropTypes.string
    ])
  ).isRequired,
  displayErrors: PropTypes.bool,
  arrowClassName: PropTypes.string,
  disablePlaceholder: PropTypes.bool,
  arrowSize: PropTypes.string,
  disabled: PropTypes.bool
};
