import qs from 'qs';
import { isEmpty } from 'validate.js';

import { ANALYTICS } from 'dpl/constants/query_params';
import { isMobileUA } from 'dpl/shared/utils';

const IS_MOBILE = isMobileUA();

export function openPuppyPage({ puppyId, analytics }, target) {
  const queryStr = !isEmpty(analytics)
    ? qs.stringify({ [ANALYTICS]: analytics })
    : null;

  const pageUrl = [`/puppy/${puppyId}`, queryStr].filter(Boolean).join('?');

  window.open(pageUrl, target || (IS_MOBILE ? '_self' : '_blank'));
}
