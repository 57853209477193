import { isGreaterThanBreakpoint } from 'dpl/util/grid';
import { isMobileUA } from 'dpl/shared/utils';
import {
  LEARNING_CENTER_HAPPY_HOME_PATH,
  LEARNING_CENTER_FLY_SAFELY_PATH,
  RECOMMENDED_TRANSPORTERS_PATH,
  LEARNING_CENTER_FLIGHT_NANNY_PATH
} from 'dpl/shared/constants/urls';

const IS_GT_LG = isGreaterThanBreakpoint('lg');

export const IS_MOBILE = isMobileUA();

export const TRANSPORT_TYPES = {
  AIR: 'air',
  GROUND: 'ground',
  PICKUP: 'pickup'
};

export const TRANSPORT_CATEGORY = {
  PICKUP: 'pickup',
  DROPOFF: 'dropoff'
};

export const TRANSPORT_KEYS = {
  PICKUP: 'pickup',
  AIRPORT_PICKUP: 'airport-pickup',
  MEET_HALFWAY: 'meet-halfway',
  GROUND_TRANSPORT: 'ground-transport',
  AIR_CARGO: 'air-cargo',
  FLIGHT_NANNY: 'flight-nanny'
};

export function getTransportModalInfo({
  breederFirstName = null,
  puppyName = null,
  facilityLocation = null
}) {
  return [
    {
      title: `Pick up ${puppyName || ''} at ${
        breederFirstName || 'your breeder'
      }’s home`,
      longDescription: `Work with ${
        breederFirstName || 'your breeder'
      } to arrange a date and time for you to pick up your puppy at ${
        breederFirstName ? `${breederFirstName}'s` : 'their'
      } home${
        facilityLocation ? ` in ${facilityLocation}` : ''
      }. You’ll cover your travel costs.`,
      link: LEARNING_CENTER_HAPPY_HOME_PATH,
      linkText: 'Learn how to drive safely with your puppy',
      tag: 'Popular',
      type: TRANSPORT_CATEGORY.PICKUP,
      key: TRANSPORT_KEYS.PICKUP
    },
    {
      title: `Meet at an airport near ${breederFirstName || 'your breeder'}`,
      longDescription: `Travel to meet ${
        breederFirstName || 'your breeder'
      } at an airport near ${facilityLocation || 'them'}. ${
        breederFirstName || 'Your breeder'
      } and your puppy will meet you there and you’ll fly back home with your puppy. You’ll cover your airfare (typically $200-$500) plus the airline fee for your puppy ($95-$150).`,
      link: LEARNING_CENTER_FLY_SAFELY_PATH,
      linkText: 'Learn how to fly safely with your puppy',
      type: TRANSPORT_CATEGORY.PICKUP,
      key: TRANSPORT_KEYS.AIRPORT_PICKUP
    },
    {
      title: `Get ${puppyName || 'your puppy'} driven to you by a transporter`,
      longDescription: `Work with ${
        breederFirstName || 'your breeder'
      } and an experienced, reputable transporter to have your puppy driven to your home. ${
        breederFirstName || puppyName
          ? 'Your puppy will be in the transporter’s care at all times during travel.'
          : ''
      } Ground transportation may cost $300 - $1,000 depending on your location.`,
      link: RECOMMENDED_TRANSPORTERS_PATH,
      linkText: 'How to find a trusted transporter',
      tag: 'Popular',
      type: TRANSPORT_CATEGORY.DROPOFF,
      key: TRANSPORT_KEYS.GROUND_TRANSPORT
    },
    {
      title: `Fly ${puppyName || 'your puppy'} home via pet-safe air cargo`,
      longDescription: `Work with ${
        breederFirstName || 'your breeder'
      } to have your puppy flown to an airport near you. ${
        breederFirstName
          ? `${breederFirstName} will drop your puppy off at their airport.`
          : ''
      } Your puppy will be in a safe cargo environment and you’ll pick them up at the airport. Pet-safe air cargo may cost $200-$500, depending on your location and airline.`,
      link: LEARNING_CENTER_FLY_SAFELY_PATH,
      linkText: 'Learn more about pet-safe air cargo',
      type: TRANSPORT_CATEGORY.DROPOFF,
      key: TRANSPORT_KEYS.AIR_CARGO
    },
    {
      title: `Fly ${puppyName || 'your puppy'} home with a flight nanny`,
      longDescription: `Work with ${
        breederFirstName || 'your breeder'
      } to find a chaperone to fly with your puppy to an airport near you. Your flight nanny will help keep your puppy safe and comfortable in the main cabin of the plane. ${
        breederFirstName || puppyName
          ? 'You can pick up your puppy at the airport or see if your flight nanny can drop your puppy off at home.'
          : ''
      } A flight nanny may cost $600 - $900, depending on your location and airline.`,
      link: LEARNING_CENTER_FLIGHT_NANNY_PATH,
      linkText: 'Learn how to work with a flight nanny',
      type: TRANSPORT_CATEGORY.DROPOFF,
      key: TRANSPORT_KEYS.FLIGHT_NANNY
    },
    {
      title: `Meet ${breederFirstName || 'your breeder'} halfway ${
        puppyName ? `to pick up ${puppyName}` : ''
      }`,
      longDescription: `Work with ${
        breederFirstName || 'your breeder'
      } to choose a location you can both travel to. ${
        breederFirstName || 'Your breeder'
      } and your puppy will meet you there. You’ll cover your travel costs.`,
      link: LEARNING_CENTER_HAPPY_HOME_PATH,
      linkText: 'Learn how to drive safely with your puppy',
      type: TRANSPORT_CATEGORY.DROPOFF,
      key: TRANSPORT_KEYS.MEET_HALFWAY
    }
  ];
}

export const AVAILABILITY_OPTIONS = [
  { title: IS_GT_LG ? 'Puppies available now' : 'Available now', value: '1' },
  { title: 'Litter expected', value: '2' },
  { title: 'Applications open', value: '3' }
];
