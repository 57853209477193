/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */

import React from 'react';
import PropTypes from 'prop-types';

import { GENDERS } from 'dpl/shared/constants/breeder_app';
import { BreederProfileLitterPropTypes } from 'dpl/shared/utils/customPropTypes';
import { getOrderedParents } from 'dpl/shared/utils/breeder_app';
import { isMobileUA } from 'dpl/shared/utils';
import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import Icon from 'dpl/common/components/Icon';

import BreederProfileFamilyOverlayParentItem from './BreederProfileFamilyOverlayParentItem';
import BreederProfileFamilyOverlayPuppyItem from './BreederProfileFamilyOverlayPuppyItem';

const { MALE } = GENDERS;

export const imageDimensions = isMobileUA()
  ? {
      width: `${document.body.getBoundingClientRect().width}px`,
      height: '267px'
    }
  : { width: '800px', height: '533px' };

export function BreederProfileFamilyOverlayTree({
  asPuppy,
  currentPuppy,
  litter,
  onPuppyClick,
  onParentClick
}) {
  const puppies = [...litter.puppies];
  const idToName = {};
  let boyCount = 1;
  let girlCount = 1;
  puppies.forEach(({ gender, id, name }) => {
    idToName[id] =
      name || (gender === MALE ? `Boy ${boyCount++}` : `Girl ${girlCount++}`);
  });

  return (
    <div className="BreederProfileFamilyOverlayTree">
      <p className="fw-medium mb4">Parents</p>
      <ul className="mb2 row">
        {getOrderedParents(litter.parents).map(p => (
          <li className="col-12" key={p.id}>
            <BreederProfileFamilyOverlayParentItem
              type={p.type}
              gender={p.gender}
              name={p.name}
              healthTierTitle={p.health_tier_title}
              photographUrl={p.profile_photo_url}
              subtitle={p.gender === MALE ? 'Dad' : 'Mom'}
              onClick={() => onParentClick(p.id)}
            />
          </li>
        ))}
      </ul>

      <p className="fw-medium mb4">{asPuppy ? 'Siblings' : 'Puppies'}</p>
      <ul>
        {puppies.map(p => {
          if (p.id === currentPuppy.id) {
            return null;
          }

          const subtitle = p.gender === MALE ? 'Brother' : 'Sister';
          return (
            <li className="mb2" key={p.id}>
              <BreederProfileFamilyOverlayPuppyItem
                name={idToName[p.id]}
                onClick={() => onPuppyClick(p.id, litter.id)}
                photographUrl={p.profile_photo_url}
                reserved={p.reserved}
                subtitle={asPuppy ? subtitle : null}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
BreederProfileFamilyOverlayTree.propTypes = {
  asPuppy: PropTypes.bool,
  currentPuppy: PropTypes.shape({
    id: PropTypes.number
  }),
  onPuppyClick: PropTypes.func.isRequired,
  onParentClick: PropTypes.func.isRequired,
  litter: PropTypes.shape(BreederProfileLitterPropTypes).isRequired
};

BreederProfileFamilyOverlayTree.defaultProps = {
  asPuppy: false,
  currentPuppy: {}
};

export function BreederProfileFamilyOverlayDetailsSection({ children, title }) {
  return (
    <div>
      <p className="fw-medium pb3 mb4 bb b--light-gray">{title}</p>
      <div className="mb8">{children}</div>
    </div>
  );
}

BreederProfileFamilyOverlayDetailsSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export function BreederProfileFamilyOverlayDetailsSectionItems({ details }) {
  return (
    <div>
      {details.map(
        ([label, value]) =>
          Boolean(value) && (
            <div key={label} className="row">
              <div className="col-sm-4 mb2">
                <p className="fw-medium f2">{label}</p>
              </div>
              <div className="col-sm-8 mb2">
                <p className="f3 ttc">{value}</p>
              </div>
            </div>
          )
      )}
    </div>
  );
}

BreederProfileFamilyOverlayDetailsSectionItems.propTypes = {
  details: PropTypes.arrayOf(PropTypes.array).isRequired
};

export default function BreederProfileFamilyOverlay({ children, onClose }) {
  return (
    <div className="BreederProfileFamilyOverlay">
      <MiniOverlayModal
        hideCloseButton
        maxWidth="800px"
        onCloseClick={onClose}
        contentClassName="self-stretch bg-white"
      >
        <button type="button" className="dn-l pl2 pt6 pb2" onClick={onClose}>
          <Icon className="black-50" name="close" />
        </button>
        {children}
      </MiniOverlayModal>
    </div>
  );
}

BreederProfileFamilyOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired
};
