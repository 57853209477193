import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isEmpty, get } from 'dpl/shared/utils/object';
import { sendAhoyAndGAEvents, sendAnalyticsIdentify } from 'dpl/util/analytics';
import { isLessThanBreakpoint } from 'dpl/util/grid';
import postSignupRedirect from 'dpl/util/postSignupRedirect';
import {
  USER_TYPES,
  PHONE_NUMBER_TYPE_OPTIONS
} from 'dpl/shared/constants/shared';
import { TOS_PATH, PRIVACY_PATH } from 'dpl/shared/constants/urls';
import { EVENT_CATEGORIES } from 'dpl/constants/analytics';
import ErrorWrapper from 'dpl/form/components/ErrorWrapper';
import GenericSelect from 'dpl/components/GenericSelect';
import Select from 'dpl/form/components/Select';
import TelephoneInput from 'dpl/form/components/TelephoneInput';

export default function SignupForm({
  buttonClassName,
  buttonText,
  formState,
  handleFormFieldChange,
  isFormSubmitting,
  countriesData,
  userType,
  formErrors,
  selectedCountry,
  referredBy,
  handleFormSubmission,
  didFormSubmissionFail,
  onAuth,
  partnership
}) {
  function handleSubmit(e) {
    e.preventDefault();

    handleFormSubmission({
      user: {
        referred_by: referredBy,
        partnership
      },
      userType
    }).then(json => {
      if (json.response.ok) {
        if (userType === USER_TYPES.BREEDER) {
          sendAhoyAndGAEvents('submit', {
            event_category: EVENT_CATEGORIES.BREEDER_SIGNUP
          });
        }

        sendAnalyticsIdentify({ userId: get(json, 'data.user.id') });

        if (onAuth) {
          return onAuth(json);
        }

        return postSignupRedirect(userType);
      }
      return null;
    });
  }

  const isBreeder = userType === USER_TYPES.BREEDER;

  const countriesOptions = countriesData.value.data.map(c => ({
    title: c.name,
    value: c.id
  }));

  return (
    <div className={classnames('SignupForm', { BreederSignupForm: isBreeder })}>
      <form onSubmit={handleSubmit}>
        <div className="row mb6">
          <div className="col">
            <label htmlFor="first_name">
              <p className="dark-gray mb1 f2 fw-medium">First Name</p>
              <ErrorWrapper
                errors={formErrors.first_name}
                isShown={didFormSubmissionFail}
              >
                <input
                  name="first_name"
                  className="w-100 br1 ba b--light-gray ph2 pv2"
                  value={formState.first_name || ''}
                  onChange={handleFormFieldChange}
                  placeholder="Jane"
                />
              </ErrorWrapper>
            </label>
          </div>
          <div className="col">
            <label htmlFor="last_name">
              <p className="dark-gray mb1 f2 fw-medium">Last Name</p>
              <input
                name="last_name"
                className="w-100 br1 ba b--light-gray ph2 pv2"
                value={formState.last_name || ''}
                onChange={handleFormFieldChange}
                placeholder="Smith"
              />
            </label>
          </div>
        </div>

        {isBreeder && (
          <Fragment>
            <div className="row mb6">
              <div className="col">
                <label htmlFor="program_name">
                  <p className="dark-gray mb1 f2 fw-medium">Program Name</p>
                  <ErrorWrapper
                    errors={formErrors.program_name}
                    isShown={didFormSubmissionFail}
                  >
                    <input
                      name="program_name"
                      className="w-100 br1 ba b--light-gray ph2 pv2"
                      value={formState.program_name || ''}
                      onChange={handleFormFieldChange}
                      placeholder="e.g. High Peaks Farm"
                    />
                  </ErrorWrapper>
                </label>
              </div>
            </div>
            <div className="row mb6">
              <div className="col">
                <label htmlFor="country_id">
                  <p className="dark-gray mb1 f2 fw-medium">Country</p>
                  <ErrorWrapper
                    errors={formErrors.country_id}
                    isShown={didFormSubmissionFail}
                  >
                    <GenericSelect
                      name="country_id"
                      onChange={handleFormFieldChange}
                      options={countriesOptions}
                      value={formState.country_id}
                    />
                  </ErrorWrapper>
                </label>
              </div>
            </div>
            {selectedCountry.allows_phone_number && (
              <div className="row mb6">
                <div className="col-md-6 col-7">
                  <TelephoneInput
                    displayErrors={didFormSubmissionFail}
                    fullWidth
                    label="Phone number"
                    name="phone_number"
                    placeholder="e.g. 555-555-5555"
                  />
                </div>
                <div className="col-md-6 col-5 nowrap">
                  <Select
                    disablePlaceholder
                    label={
                      isLessThanBreakpoint('md') ? 'Type' : 'Phone number type'
                    }
                    name="phone_number_type"
                    options={PHONE_NUMBER_TYPE_OPTIONS}
                    value={formState.phone_number_type || 'mobile'}
                  />
                </div>
              </div>
            )}
          </Fragment>
        )}

        <div className="mb6">
          <label htmlFor="email">
            <p className="dark-gray mb1 f2 fw-medium">Email</p>
            <ErrorWrapper
              errors={formErrors.email}
              isShown={didFormSubmissionFail}
            >
              <input
                name="email"
                autoCapitalize="none"
                className="w-100 br1 ba b--light-gray ph2 pv2"
                value={formState.email || ''}
                onChange={handleFormFieldChange}
                placeholder="jane@highpeaksfarm.com"
              />
            </ErrorWrapper>
          </label>
        </div>

        <div className="mb6">
          <label htmlFor="password">
            <p className="dark-gray mb1 f2 fw-medium">Password</p>
            <ErrorWrapper
              errors={formErrors.password}
              isShown={didFormSubmissionFail}
            >
              <input
                name="password"
                type="password"
                autoCapitalize="none"
                className="w-100 br1 ba b--light-gray ph2 pv2"
                value={formState.password || ''}
                onChange={handleFormFieldChange}
                placeholder="6+ characters"
              />
            </ErrorWrapper>
          </label>
        </div>

        <div className="mb6">
          <button
            data-test-id="signup_submit"
            type="submit"
            disabled={isFormSubmitting || !isEmpty(formErrors)}
            className={classnames(buttonClassName, {
              'button--loading': isFormSubmitting
            })}
          >
            {buttonText}
          </button>
        </div>

        <div className="mb6">
          {isBreeder && (
            <p className="f2 mid-gray tc pb4">
              By entering your mobile number and clicking “Continue,” you are
              agreeing to receive occasional automated promotional text messages
              from Good Dog at that number to keep you updated on all our
              benefits. Your consent is not a condition of any purchase, and
              remember, it’s always free to join our community!
            </p>
          )}
          <p className="f2 mid-gray tc">
            By continuing, I agree to Good Dog&apos;s&nbsp;
            {isBreeder && (
              <Fragment>
                <a className="underline" href="/breeder-code-of-ethics">
                  Breeder Code of Ethics
                </a>
                ,&nbsp;
              </Fragment>
            )}
            <a className="underline" href={TOS_PATH}>
              Terms of Service
            </a>
            &nbsp;and{' '}
            <a className="underline" href={PRIVACY_PATH}>
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </form>
    </div>
  );
}

SignupForm.propTypes = {
  formState: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    password: PropTypes.string,
    program_name: PropTypes.string,
    country_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phone_number_type: PropTypes.string
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object.isRequired,
  didFormSubmissionFail: PropTypes.bool.isRequired,
  handleFormFieldChange: PropTypes.func.isRequired,
  handleFormSubmission: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  userType: PropTypes.oneOf(Object.values(USER_TYPES)).isRequired,
  onAuth: PropTypes.func,
  buttonText: PropTypes.string,
  buttonClassName: PropTypes.string,
  referredBy: PropTypes.string,
  countriesData: PropTypes.shape({
    value: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      ).isRequired
    }).isRequired
  }).isRequired,
  selectedCountry: PropTypes.shape({
    allows_phone_number: PropTypes.bool
  }),
  partnership: PropTypes.string
};

SignupForm.defaultProps = {
  onAuth: null,
  buttonText: 'Continue',
  buttonClassName: 'w-100 button button--primary',
  referredBy: null,
  selectedCountry: {},
  partnership: null
};
