import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function GenericMenu({
  setIsMenuOpen,
  className,
  closeOnClickOutside,
  children
}) {
  const ref = useRef();

  function handleBodyClick(e) {
    if (ref.current?.contains(e.target) === false) {
      setIsMenuOpen(false);
    }
  }

  function removeBodyClick() {
    document.body.removeEventListener('click', handleBodyClick);
  }

  useEffect(() => {
    if (closeOnClickOutside) {
      document.body.addEventListener('click', handleBodyClick);
    } else {
      removeBodyClick();
    }

    return removeBodyClick;
  }, [closeOnClickOutside]);

  return (
    <div
      className={classnames(
        'GenericMenu ph4-lg ph5 br3-l ba-l b--light-gray bg-white',
        className
      )}
      ref={ref}
    >
      <div className="dn-l db">
        <div className="flex items-center justify-center">
          <button
            className="GenericMenu__grabber"
            type="button"
            onClick={() => setIsMenuOpen(false)}
          >
            <span />
          </button>
        </div>
      </div>
      {children}
    </div>
  );
}

GenericMenu.propTypes = {
  className: PropTypes.string,
  setIsMenuOpen: PropTypes.func.isRequired,
  closeOnClickOutside: PropTypes.bool,
  children: PropTypes.node.isRequired
};

GenericMenu.defaultProps = {
  className: '',
  closeOnClickOutside: false
};
