import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { isEqualFormValues } from 'dpl/shared/form/utils';
import { CONSTRAINT_TYPES } from 'dpl/shared/validations/constraints';
import { USER_TYPES } from 'dpl/shared/constants/shared';
import {
  userSignupResourceDefinition,
  countriesResourceDefinition
} from 'dpl/shared/utils/tptConnectResourceDefinitions';
import withFormCapabilities from 'dpl/form/decorators/withFormCapabilities';
import SignupForm from 'dpl/components/SignupForm';

function SignupFormContainer(props) {
  return <SignupForm {...props} />;
}

function propsToInitialFormState({
  countriesData: {
    value: { data }
  }
}) {
  return {
    country_id: data[0] && data[0].id
  };
}

export default compose(
  __DEPRECATED_defineResources(() => ({
    sessionData: userSignupResourceDefinition,
    countriesData: countriesResourceDefinition
  })),
  // we don't want formState to ever update here, bc the server never returns
  // the value the user entered
  connect(
    (
      _,
      {
        countriesData: {
          value: { data }
        }
      }
    ) => ({
      findCountry: countryId =>
        data.find(c => isEqualFormValues(c.id, countryId))
    })
  ),
  withFormCapabilities(propsToInitialFormState, {
    mapPropsAndStateToValidationSchema: (
      { findCountry, userType },
      formState
    ) => {
      let baseConstraints = {
        first_name: CONSTRAINT_TYPES.NON_EMPTY,
        email: formState.email ? CONSTRAINT_TYPES.EMAIL : CONSTRAINT_TYPES.TEXT
      };
      const selectedCountry = findCountry(formState.country_id) || {};

      if (userType === USER_TYPES.BREEDER) {
        baseConstraints = {
          ...baseConstraints,
          program_name: CONSTRAINT_TYPES.BREEDER_PROGRAM_NAME
        };

        if (selectedCountry.allows_phone_number) {
          baseConstraints = {
            ...baseConstraints,
            phone_number: !formState.phone_number
              ? CONSTRAINT_TYPES.TEXT
              : CONSTRAINT_TYPES.TELEPHONE,
            country_id: CONSTRAINT_TYPES.TEXT
          };
        }

        return {
          ...baseConstraints,
          country_id: CONSTRAINT_TYPES.TEXT
        };
      }

      return baseConstraints;
    },
    onSubmit: (props, formStateForSubmission, signupOpts) =>
      props.sessionData.submit({
        user: {
          ...formStateForSubmission,
          ...signupOpts.user
        },
        user_type: signupOpts.userType
      })
  }),
  connect((_, { findCountry, formState }) => ({
    selectedCountry: findCountry(formState.country_id)
  }))
)(SignupFormContainer);
