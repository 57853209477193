export const FOLLOWABLE_TYPES = {
  BREEDER_BREED: 'BreederBreed',
  BREED: 'Breed',
  DOG: 'Dog'
};

export const FOLLOWABLE_STATUS_MESSAGE = {
  ADDED_TO_FAVORITES: 'Added to',
  REMOVED_FROM_FAVORITES: 'Removed from'
};

export const FOLLOWABLE_STATUSES = {
  ADDED: 'added',
  REMOVED: 'removed'
};
