import isPreviewPage from 'dpl/shared/utils/isPreviewPage';

export function dogModalsLitterResourceDefintion(litterId) {
  return {
    url: `/api/dog_modals/litters/${litterId}.json`,
    defaultValue: {
      data: {
        breeder_profile: {
          litter_ids: []
        },
        parents: [],
        photographs: [],
        puppies: []
      }
    },
    params: {
      preview: isPreviewPage()
    }
  };
}

export function dogResourceDefinition(dogId) {
  return {
    url: `/api/dog_modals/dogs/${dogId}.json`,
    defaultValue: {
      data: {
        health_test_results: [],
        pedigree_documents: []
      }
    },
    enabled: Boolean(dogId)
  };
}
