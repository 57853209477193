import React from 'react';
import PropTypes from 'prop-types';

import { GENDERS } from 'dpl/shared/constants/breeder_app';
import ClickableDiv from 'dpl/common/components/ClickableDiv';
import SmartImage from 'dpl/components/SmartImage';
import HealthTierBadge from 'dpl/common/components/HealthTierBadge';
import DogPlaceholderSvg from 'dpl/common/components/DogPlaceholderSvg';

const { FEMALE, MALE } = GENDERS;

function TbdDogParentItem({ gender }) {
  return (
    <div className="mb4">
      <div className="mb2">
        <DogPlaceholderSvg width="200px" height="151px" />
      </div>
      <p className="truncate">
        <span className="fw-medium">TBD,</span>{' '}
        {gender === GENDERS.FEMALE ? 'Mom' : 'Dad'}
      </p>
    </div>
  );
}

TbdDogParentItem.propTypes = {
  gender: PropTypes.oneOf([FEMALE, MALE]).isRequired
};

export default function BreederProfileFamilyOverlayParentItem({
  type,
  gender,
  photographUrl,
  name,
  healthTierTitle,
  onClick
}) {
  return (
    <div className="BreederProfileFamilyOverlayParentItem">
      {type === 'TbdDog' ? (
        <TbdDogParentItem gender={gender} />
      ) : (
        <ClickableDiv onClick={onClick} className="mb4">
          <div className="box-shadow-hover hide-child dib">
            <div className="mb2 relative f0">
              {photographUrl ? (
                <SmartImage
                  Tag="div"
                  className="bg-center cover br1"
                  crop
                  src={photographUrl}
                  width="200px"
                  height="151px"
                />
              ) : (
                <DogPlaceholderSvg width="200px" height="151px" />
              )}
              <span className="f1 bg-white-80 absolute absolute--fill child flex items-center justify-center stone-700">
                View
              </span>
            </div>
          </div>
          <p className="truncate mb1">
            <span className="fw-medium">{name},</span>{' '}
            {gender === GENDERS.FEMALE ? 'Mom' : 'Dad'}
          </p>
          {healthTierTitle && (
            <HealthTierBadge healthTierTitle={healthTierTitle} />
          )}
        </ClickableDiv>
      )}
    </div>
  );
}

BreederProfileFamilyOverlayParentItem.propTypes = {
  type: PropTypes.string,
  gender: PropTypes.oneOf([FEMALE, MALE]).isRequired,
  photographUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  healthTierTitle: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

BreederProfileFamilyOverlayParentItem.defaultProps = {
  photographUrl: null,
  healthTierTitle: null,
  type: null
};
